// @file Email helpers

// Same as in email_validator.rb
const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

function isEmail(item: string): boolean {
  return EMAIL_REGEX.test(item)
}

function isWallwisherEmail(item: string): boolean {
  return isEmail(item) && item.endsWith('@wallwisher.com')
}

export { isEmail, isWallwisherEmail }
